function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import Image from '@landfolk/shared/components/Image';
import Link from 'next/link';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "z6w44f",
  styles: "margin-bottom:2rem;margin-top:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:2rem;line-height:2rem;@media (min-width: 64em){margin-bottom:3rem;margin-top:2rem;font-size:3rem;line-height:3rem;}"
} : {
  name: "1u5t8om-Home",
  styles: "margin-bottom:2rem;margin-top:1rem;font-family:var(--font-serif), ui-serif, Georgia, Cambria, \"Times New Roman\", Times, serif;font-size:2rem;line-height:2rem;@media (min-width: 64em){margin-bottom:3rem;margin-top:2rem;font-size:3rem;line-height:3rem;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "uz5at",
  styles: "max-width:65ch"
} : {
  name: "1cwrhk1-Home",
  styles: "max-width:65ch;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1ws7ciu",
  styles: "margin-bottom:1rem;font-size:1.25rem;line-height:1.5rem;@media (min-width: 64em){font-size:1.5rem;line-height:2rem;}"
} : {
  name: "1dqtzmo-Home",
  styles: "margin-bottom:1rem;font-size:1.25rem;line-height:1.5rem;@media (min-width: 64em){font-size:1.5rem;line-height:2rem;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "14ownvp",
  styles: "margin-top:3rem;display:grid;max-width:65ch;grid-template-columns:repeat(6, minmax(0, 1fr));gap:0.25rem;border-top-width:1px;--tw-border-opacity:1;border-color:rgb(204 210 210 / var(--tw-border-opacity));padding-top:2rem;@media (min-width: 64em){align-items:center;}"
} : {
  name: "1k5pfx6-Home",
  styles: "margin-top:3rem;display:grid;max-width:65ch;grid-template-columns:repeat(6, minmax(0, 1fr));gap:0.25rem;border-top-width:1px;--tw-border-opacity:1;border-color:rgb(204 210 210 / var(--tw-border-opacity));padding-top:2rem;@media (min-width: 64em){align-items:center;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "ovk7qm",
  styles: "grid-column:span 2 / span 2;display:grid;grid-template-columns:repeat(4, minmax(0, 1fr));column-gap:1rem;row-gap:0.5rem"
} : {
  name: "1sef0v-Home",
  styles: "grid-column:span 2 / span 2;display:grid;grid-template-columns:repeat(4, minmax(0, 1fr));column-gap:1rem;row-gap:0.5rem;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "ajyg40",
  styles: "@media (max-width: 63.999em){grid-column:span 2 / span 2;grid-column-start:2;}"
} : {
  name: "2uykkd-Home",
  styles: "@media (max-width: 63.999em){grid-column:span 2 / span 2;grid-column-start:2;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "j033lg",
  styles: "border-radius:9999px"
} : {
  name: "g5seib-Home",
  styles: "border-radius:9999px;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1ld079h",
  styles: "grid-column:1 / -1;font-size:0.75rem;line-height:1rem;@media (min-width: 64em){grid-column:span 5 / span 5;grid-column-start:2;align-self:center;}@media (max-width: 63.999em){text-align:center;}"
} : {
  name: "1qjza42-Home",
  styles: "grid-column:1 / -1;font-size:0.75rem;line-height:1rem;@media (min-width: 64em){grid-column:span 5 / span 5;grid-column-start:2;align-self:center;}@media (max-width: 63.999em){text-align:center;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "rml8qn-Home",
  styles: "font-weight:600;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "bgcn2g",
  styles: "--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "1rirk5d-Home",
  styles: "--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1774shm",
  styles: "grid-column:span 2 / span 2;grid-column-start:3;display:grid;grid-template-columns:repeat(4, minmax(0, 1fr));column-gap:1rem;row-gap:0.5rem"
} : {
  name: "1jtaooe-Home",
  styles: "grid-column:span 2 / span 2;grid-column-start:3;display:grid;grid-template-columns:repeat(4, minmax(0, 1fr));column-gap:1rem;row-gap:0.5rem;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "ajyg40",
  styles: "@media (max-width: 63.999em){grid-column:span 2 / span 2;grid-column-start:2;}"
} : {
  name: "2uykkd-Home",
  styles: "@media (max-width: 63.999em){grid-column:span 2 / span 2;grid-column-start:2;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "j033lg",
  styles: "border-radius:9999px"
} : {
  name: "g5seib-Home",
  styles: "border-radius:9999px;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "1ld079h",
  styles: "grid-column:1 / -1;font-size:0.75rem;line-height:1rem;@media (min-width: 64em){grid-column:span 5 / span 5;grid-column-start:2;align-self:center;}@media (max-width: 63.999em){text-align:center;}"
} : {
  name: "1qjza42-Home",
  styles: "grid-column:1 / -1;font-size:0.75rem;line-height:1rem;@media (min-width: 64em){grid-column:span 5 / span 5;grid-column-start:2;align-self:center;}@media (max-width: 63.999em){text-align:center;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref15 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "rml8qn-Home",
  styles: "font-weight:600;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref16 = process.env.NODE_ENV === "production" ? {
  name: "bgcn2g",
  styles: "--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "1rirk5d-Home",
  styles: "--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref17 = process.env.NODE_ENV === "production" ? {
  name: "yq3ayv",
  styles: "grid-column:span 2 / span 2;grid-column-start:5;display:grid;grid-template-columns:repeat(4, minmax(0, 1fr));column-gap:1rem;row-gap:0.5rem"
} : {
  name: "20of6t-Home",
  styles: "grid-column:span 2 / span 2;grid-column-start:5;display:grid;grid-template-columns:repeat(4, minmax(0, 1fr));column-gap:1rem;row-gap:0.5rem;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref18 = process.env.NODE_ENV === "production" ? {
  name: "ajyg40",
  styles: "@media (max-width: 63.999em){grid-column:span 2 / span 2;grid-column-start:2;}"
} : {
  name: "2uykkd-Home",
  styles: "@media (max-width: 63.999em){grid-column:span 2 / span 2;grid-column-start:2;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref19 = process.env.NODE_ENV === "production" ? {
  name: "j033lg",
  styles: "border-radius:9999px"
} : {
  name: "g5seib-Home",
  styles: "border-radius:9999px;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref20 = process.env.NODE_ENV === "production" ? {
  name: "1ld079h",
  styles: "grid-column:1 / -1;font-size:0.75rem;line-height:1rem;@media (min-width: 64em){grid-column:span 5 / span 5;grid-column-start:2;align-self:center;}@media (max-width: 63.999em){text-align:center;}"
} : {
  name: "1qjza42-Home",
  styles: "grid-column:1 / -1;font-size:0.75rem;line-height:1rem;@media (min-width: 64em){grid-column:span 5 / span 5;grid-column-start:2;align-self:center;}@media (max-width: 63.999em){text-align:center;};label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref21 = process.env.NODE_ENV === "production" ? {
  name: "16ceglb",
  styles: "font-weight:600"
} : {
  name: "rml8qn-Home",
  styles: "font-weight:600;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref22 = process.env.NODE_ENV === "production" ? {
  name: "bgcn2g",
  styles: "--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity))"
} : {
  name: "1rirk5d-Home",
  styles: "--tw-text-opacity:1;color:rgb(103 121 122 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function Home() {
  return _jsxs(_Fragment, {
    children: [_jsx("h1", {
      css: _ref,
      children: "Landfolk Design System"
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsx("p", {
        css: _ref3,
        children: "It\u2019s important that Landfolk appears recognizable on all platforms and all over the world."
      }), _jsxs("p", {
        children: ["We\u2019ve named our identity LDS \u2013 Landfolk Design System. In addition to this page, which contains components for use on landfolk.com, you can", ' ', _jsx(Link, {
          href: "Landfolk_Design_Guidelines_v1.pdf",
          children: "download the guidelines describing use of logo, colors, typography etc. as a PDF here"
        }), "."]
      })]
    }), _jsxs("div", {
      css: _ref4,
      children: [_jsxs("div", {
        css: _ref5,
        children: [_jsx("div", {
          css: _ref6,
          children: _jsx(Image, {
            css: _ref7,
            url: "/team/chris.jpg",
            width: 300,
            height: 300,
            alt: "Chris"
          })
        }), _jsxs("div", {
          css: _ref8,
          children: [_jsx("span", {
            css: _ref9,
            children: _jsx(Link, {
              href: "mailto:chris@landfolk.com",
              children: "Chris Kj\xE6r S\xF8rensen"
            })
          }), _jsx("br", {}), _jsx("span", {
            css: _ref10,
            children: "CTO"
          })]
        })]
      }), _jsxs("div", {
        css: _ref11,
        children: [_jsx("div", {
          css: _ref12,
          children: _jsx(Image, {
            css: _ref13,
            url: "/team/lars.jpg",
            width: 300,
            height: 300,
            alt: "Lars"
          })
        }), _jsxs("div", {
          css: _ref14,
          children: [_jsx("span", {
            css: _ref15,
            children: _jsx(Link, {
              href: "mailto:lars@landfolk.com",
              children: "Lars Kloster Silkj\xE6r"
            })
          }), _jsx("br", {}), _jsx("span", {
            css: _ref16,
            children: "Head of Design"
          })]
        })]
      }), _jsxs("div", {
        css: _ref17,
        children: [_jsx("div", {
          css: _ref18,
          children: _jsx(Image, {
            css: _ref19,
            url: "https://landfolk-storage.imgix.net/eped6wzh6g871blghj1ak29exu3j",
            width: 300,
            height: 300,
            alt: "Kaya"
          })
        }), _jsxs("div", {
          css: _ref20,
          children: [_jsx("span", {
            css: _ref21,
            children: _jsx(Link, {
              href: "mailto:kaya.olsen@landfolk.com",
              children: "Kaya Olsen"
            })
          }), _jsx("br", {}), _jsx("span", {
            css: _ref22,
            children: "UX Writer"
          })]
        })]
      })]
    })]
  });
}